:root {
    --header-bg-color: #333;
    --header-text-color: #ED6F33;
    --header-hover-color: #F7BD33;
}

.site-header {
    background-color: var(--header-bg-color);
    color: var(--header-text-color);
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform 0.3s ease;
}

.site-header.sticky {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-logo {
    display: flex;
    align-items: center;
    gap: 20px;
    /* width: 400px;
    max-width: 400px; */
    height: auto;
}

.header-logo img {
    max-height: 80px;
    width: auto;
}

.header-nav ul {
    list-style: none;
    display: flex;
    gap: 80px;
    padding: 0;
    margin: 0 24px 0 24px;
    justify-content: flex-end;
}

.header-nav a {
    color: var(--header-text-color);
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.header-nav a:hover {
    color: var(--header-hover-color);
}

.menu-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1001;
}

.hamburger {
    width: 100%;
    height: 3px;
    background-color: #ED6F33;
    transition: all 0.3s ease;
    border-radius: 2px;
}

.menu-toggle.open .hamburger:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
}

.menu-toggle.open .hamburger:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open .hamburger:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
}

.login-button a {
    text-decoration: none;
    font-size: 16px;
    color: #ED6F33;
    padding: 10px 24px;
    border: 1px solid #ED6F33;
    border-radius: 6px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.login-button a:hover {
    background-color: #4FBBEB;
    border: 1px solid #4FBBEB;
    color: #fff;
}

@media (max-width: 1180px) {

    .header-content {
        padding: 10px;
        width: 100%;
    }

    .header-logo {
        font-size: 1.2rem;
    }

    .header-logo img {
        max-height: 60px;
    }

    .header-nav {
        position: fixed;
        top: 78px;
        left: -100%;
        height: calc(100vh - 60px);
        background-color: var(--header-bg-color);
        transition: left 0.3s ease;
    }

    .header-nav.open {
        left: 0;
    }

    .header-nav ul {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .menu-toggle {
        display: flex;
        margin-right: 60px;
        order: -1;
    }

    .site-title {
        display: none;
    }

    .login-button {
        order: 1;
    }
}

@media (max-width: 1180px) {
    .menu-toggle {
        margin-right: 20px;
    }
}