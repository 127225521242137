:root {
    --footer-nav-primary-color: #ED6F33;
    --footer-nav-secondary-color: #4FBBEB;
    --footer-nav-hover-color: #F7BD33;
}

.nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1em 1em 1em;
}

.nav-links ul {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

.nav-links li {
    margin: 25px 0;
}

.nav-links a {
    text-decoration: none;
    color: var(--footer-nav-primary-color);
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: var(--footer-nav-hover-color);
}

.nav-social {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.nav-social a {
    margin: 0 10px;
    text-decoration: none;
    color: var(--footer-nav-primary-color);
    font-size: 24px;
    transition: color 0.3s ease;
}

.nav-social a:hover {
    color: var(--footer-nav-secondary-color);
}

.social-title {
    text-decoration: underline;
    margin-bottom: 10px;
    text-align: center;
}

.hours-of-operation {
    text-align: center;
    margin-bottom: 20px;
}

.hours-of-operation h3 {
    margin-top: 0;
    font-size: 1.2rem;
    text-decoration: underline;
}

.hours-of-operation p {
    margin-bottom: 5px;
}

@media (max-width: 480px) {
    .nav-links ul {
        flex-direction: column;
        align-items: center;
    }

    .nav-links li {
        margin: 15px 0;
    }

    .hours-of-operation {
        font-size: 0.9rem;
    }

    .footer-copy {
        font-size: 0.8rem;
    }
}